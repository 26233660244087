import axios from 'axios'
import config from '@/config/config'
import store from '@/store'
import { getToken, removeAll,getUserInfo } from '@/utils/auth'

import { Notification,Message } from 'element-ui'

let whiteList = []
let request_data_methods = ''

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: config.BASE_API_URL + "api/",

  // 请求超时时间
  timeout: 20000,
})

/**
 * 异常拦截处理器
 *
 * @param {*} error
 */
const errorHandler = error => {
  // 判断是否是响应错误信息
  if (error.response) {
    console.log('error.response',error.response);
    if (error.response.status == 401) {
      removeAll()
      location.reload()
    } else if (error.response.status == 403) {
      Notification({
        title: '警告',
        type: 'warning',
        message: '无权限操作 ...',
        position: 'top-right',
      })
    } else {
      Notification({
        message: '网络异常,请稍后再试...',
        position: 'top-right',
      })
    }
  }

  return Promise.reject(error)
}

// 请求拦截器
request.interceptors.request.use(config => {
  let data = config.data
  if(Reflect.has(data,'method')){
    request_data_methods = data.method
  }
  const token = getToken()
  const userInfo = getUserInfo()
  if (token) {
    config.headers['user-token'] = token
    config.headers.jti = userInfo.jti
    config.headers.platform = userInfo.platform
  }


  return config
}, errorHandler)

// 响应拦截器
request.interceptors.response.use(response => {
  if(response.data.code != 1){
    console.log(whiteList.indexOf(request_data_methods));
    if(whiteList.indexOf(request_data_methods) < 0){
      Message.error(response.data.msg)
    }
    if(response.data.code == '-203' || response.data.code == '-202'){
      setTimeout(() => {
        store.dispatch("ACT_USER_LOGOUT");
      }, 1000);
    }
  }
  return response.data


  // return  Promise.reject(response.data)

}, errorHandler)

/**
 * GET 请求
 *
 * @param {String} url
 * @param {Object} data
 * @param {Object} options
 * @returns {Promise<any>}
 */
export const get = (url, data = {}, options = {}) => {
  return request({
    url,
    params: data,
    method: 'get',
    ...options,
  })
}

/**
 * POST 请求
 *
 * @param {String} url
 * @param {Object} data
 * @param {Object} options
 * @returns {Promise<any>}
 */
export const post = (url, data = {}, options = {}) => {
  return request({
    url,
    method: 'post',
    data: data,
    ...options,
  })
}

/**
 * 上传文件 POST 请求
 *
 * @param {String} url
 * @param {Object} data
 * @param {Object} options
 * @returns {Promise<any>}
 */
export const upload = (url, data = {}, options = {}) => {
  return request({
    url,
    method: 'post',
    data: data,
    ...options,
  })
}
